import React from "react";
import { useParams } from "react-router-dom";
function TheAchievementCard() {
  const { cardId } = useParams();
  let cardText = "";
  let cardText2 = "";
  let cardTextMain = "";
  if (cardId === "1") {
    cardTextMain = `  Самым важным правовым актом, принятым новым Верховным Советом, была Декларация государственного суверенитета казахской ССР принятая 25 октября 1990 года.`;
    cardText2 = `  Он провозгласил право Казахстана приостанавливать на своей территории законы и другие акты высших органов Союза, нарушающие его суверенные права и Конституцию`;
    cardText = `
    Политические процессы, происходящие в обществе в начале 90-х годов, приобрели поразительный темп. На волне лозунгов реконструкции и гласности в марте 1990 года состоялись выборы в Верховный Совет Казахской ССР. Это были первые демократические выборы в Верховный законодательный орган. Избраны представители различных слоев населения: рабочие, военнослужащие, выдвинутые из научных учреждений. Политические пристрастия депутатов также были разными. В то время как некоторые из них следовали старым советским традициям, большинство народных избранников также хотели и требовали интенсивных изменений. Именно этот депутатский корпус принял целый пакет важных актов, положивших начало становлению независимости.
 `;
  } else if (cardId === "2") {
    cardText = `
    В начале декабря 1991 г. лидеры трех республик: России - Б.Ельцин, Украины Л.Кравчук и Беларуси – С.Шушкевич подписали Беловежское соглашение о денонсации союзного договора 1922 г. Фактически распад СССР был юридически оформлен.
10 декабря 1991 г. Верховный Совет принял решение о переименовании Казахской ССР в Республику Казахстан,а 16 декабря 1991 г. Верховный Совет провозгласил государственную независимость Республики Казахстан. Начался новый этап в истории республики.
Процесс рождения нового государства правопреемствующего Казахской ССР и Союзу СССР, пошел.
Особым этапом этого процесса стал Конституционный Закон  `;
    cardText2 = `от 16 декабря 1991 г. Развивая ключевые идеи Декларации о государственном суверенитете, Конституционный Закон однозначно закрепил, что Республика Казахстан отныне строит свои взаимоотношения со всеми государствами на принципах международного права, как и подобает независимому государству. Впервые было установлено единое казахстанское гражданство. Узаконивалось провозглашенное Декларацией многообразие форм собственности, принцип разделения государственной власти на законодательную, исполнительную и судебную, закреплялся курс государства на самостоятельную экономическую систему со своей финансово- кредитной, налоговой и таможенной политикой
    `;
    cardTextMain = `“О государственной независимости Республики Казахстан” `;
  } else if (cardId === "3") {
    cardText = `
    Конституция РК, принятая на республиканском референдуме 30 августа 1995 года, является основным законом страны. Он призывает к утверждению конституционного строя и его основ, прав и свобод человека и гражданина, Основ государственной организации, а также основ организации осуществления власти. Конституция Республики Казахстан имеет высшую юридическую силу по отношению к другим правовым актам: ни один принимаемый в стране правовой акт (конституционный закон, Указ Президента РК, правовой акт региона, решение суда и т.д.) не может противоречить основному закону, а в случае противоречия (правовая коллизия) нормы Конституции имеют приоритет.
`;
    cardTextMain = ` Конституция РК является ядром правовой системы государства, основой развития текущего (отраслевого) законодательства.`;
    cardText2 = `В Конституции 1995 г. был закреплён конституционный статус человека и гражданина, всего народа как источника государственной власти и социальной базы государства. Конституция признаёт государство социальным, это значит, что государство рассматривает социальную политику как эффективную защиту населения и создание условий жизни всего народа.`;
  } else if (cardId === "4") {
    cardText = `
    Государственная программа "Культурное наследие" - культурный, экономический и социальный капитал, структурная часть общечеловеческой культуры, истоки развития и становления этноса, общества, человеческого разума, программа создания и защиты важного хранилища исторических воспоминаний. Он исследует исторический опыт многогранного общества, необходимого человечеству в современных спорах, создавая путь, соединяющий основы древней истории с сегодняшним днем, демонстрируя непрерывную связь времени Информирует об историко-культурном наследии, рассказывает не только историю, но и то, что произойдет в будущем. Поэтому глава государства 15 лет назад, в апреле 2003 года, в Послании народу Казахстана поручил реализовать специальную государственную программу `;
    cardTextMain = ` «Культурное наследие». `;
    cardText2 = `Принятая Посланием Главы государства государственная программа "Культурное наследие" является свидетельством мирового процветания современного Казахстана`;
  } else if (cardId === "5") {
    cardText = `
    Первый Съезд мировых лидеров и лидеров традиционных религий прошел в столице Казахстана, Астане с 23 по 24 сентября 2003 года. Это мероприятие было организовано по инициативе Президента Казахстана Нурсултана Назарбаева, и была важным шагом для обсуждения вопросов мира, толерантности и диалога между вероисповеданиями.
В съезде приняли участие более 100 религиозных лидеров и представителей из более чем 40 стран и различных вероисповеданий, включая христианство, ислам, буддизм, иудаизм, ислам и другие.
Главной темой съезда была необходимость укрепления диалога и понимания между разными религиями и культурами в свете угрозы терроризма, ксенофобии и религиозной нетерпимости. Участники обсуждали вопросы мира, морали, молодежи и религиозного образования.
 В рамках съезда была принята `;
    cardTextMain = ` "Декларация о духовных основах мира и предотвращении войн" `;
    cardText2 = `Этот документ выразил обязательство участников по укреплению мира, справедливости и диалога между вероисповеданиями.
    Съезд включал в себя разнообразные культурные мероприятия, выставки и концерты, чтобы продемонстрировать богатство и разнообразие культурных традиций.
   Первый Съезд мировых лидеров и лидеров традиционных религий в Астане получил признание за свою значимость в продвижении интеррелигиозного диалога и мира. Это мероприятие стало традиционным и проводится раз в три года, привлекая религиозных лидеров и представителей со всего мира.
   `;
  } else if (cardId === "7") {
    cardText = `
    Азиатские зимние игры 2011, также известные как 7-ые Азиатские зимние игры, были международным спортивным мероприятием, проведенным в Казахстане с 30 января по 6 февраля 2011 года. Эти игры стали крупнейшим мероприятием в области зимних видов спорта в регионе Азии и собрали спортсменов из различных азиатских стран.
    Игры прошли в нескольких городах Казахстана, включая Алматы, Астану, Тараз и Шымкент. Это было важным событием для спортивной инфраструктуры Казахстана.
    Игры собрали более  `;
    cardTextMain = ` 800 спортсменов из 25 азиатских стран `;
    cardText2 = `Соревнования включали виды спорта, такие как лыжные гонки, биатлон, фигурное катание, конькобежный спорт, хоккей и многие другие.
    Для размещения участников Азиатских зимних игр была построена специальная олимпийская деревня в Алматы.
    Казахстан вложил средства в развитие своей спортивной инфраструктуры, строя новые объекты и обновляя существующие для подготовки к мероприятию.
   Одной из целей Азиатских зимних игр 2011 было укрепление сотрудничества и дружбы между азиатскими странами через спорт, а также продвижение зимних видов спорта в регионе.
    Спортсмены из разных стран соревновались за медали в различных дисциплинах. Казахстан занял лидирующие позиции в медальном зачёте и показал впечатляющие результаты в ряде видов спорта.
   `;
  } else if (cardId === "8") {
    cardText = `ОБСЕ
    Председательство Казахстана в Организации по безопасности и сотрудничеству в Европе (ОБСЕ) в 2010 году было значимым событием для этой страны и для региона в целом. Это было первым председательством Казахстана в ОБСЕ, и оно продолжалось с 1 января по 31 декабря 2010 года. Казахстан впервые стал азиатской страной, которая председательствовала в ОБСЕ, и это вызвало большой интерес и внимание международного сообщества.
    
     `;

    cardTextMain = `Казахстан впервые стал азиатской страной, которая председательствовала в ОБСЕ, и это вызвало большой интерес и внимание международного сообщества.`;
    cardText2 = (
      <div className="  text-lg">
        <h1 className="font-semibold">
          Во время своего председательства Казахстан сосредоточил внимание на
          нескольких ключевых областях:
        </h1>
        1. Борьба с транснациональными угрозами: Казахстан акцентировал внимание
        на борьбе с терроризмом, транснациональной преступностью и
        распространением наркотиков в регионе. Он призывал к усилению
        международного сотрудничества для борьбы с этими угрозами.
        <br />
        2. Развитие и содействие диалогу: Казахстан активно содействовал
        усилению политического диалога между странами-участницами ОБСЕ. Это
        включало в себя посредничество в разрешении конфликтов и поддержку
        дипломатических усилий.
        <br />
        3. Продвижение прав человека: Казахстан подчеркивал важность соблюдения
        прав человека в своем председательстве. Он уделял внимание вопросам
        свободы СМИ, свободе собраний и правам меньшинств.
        <br />
        4. Экономическое сотрудничество: Казахстан также поднимал вопросы
        экономического сотрудничества и развития в рамках ОБСЕ.
        <p>
          Председательство Казахстана в ОБСЕ в 2010 году было оценено как
          конструктивное и положительное, и оно позволило стране укрепить свою
          международную репутацию. Казахстан продолжил активное участие в
          деятельности ОБСЕ и после завершения своего председательства,
          продолжая работу по ряду важных вопросов, связанных с безопасностью и
          сотрудничеством в регионе.
        </p>
      </div>
    );
  } else if (cardId === "9") {
    cardText = `
    Международная выставка "ЭКСПО 2017" была проведена в столице Казахстана, городе Астана,  с 10 июня по 10 сентября 2017 года. Эта выставка была организована под девизом "Энергия будущего" и была посвящена вопросам устойчивой энергетики и инноваций.

    Основной темой мероприятия были вопросы альтернативных источников энергии, энергосбережения и устойчивой энергетики. В связи с этим мероприятие привлекло внимание экологов, инженеров, ученых и представителей бизнеса со всего мира.
    
    Участники  "ЭКСПО 2017" привлек множество участников из разных стран. `;
    cardTextMain = ` Более 100 стран и международных организаций приняли участие в выставке, представив свои инновации и разработки в области устойчивой энергетики `;
    cardText2 = `
    Павильоны и выставочные объекты.  В рамках мероприятия были построены различные павильоны и архитектурные объекты, символизирующие тему энергии и инноваций. Например, "Астанинская сфера" - гигантский шар, ставший символом выставки.
    
    Экспо 2017 в Астане была важным мероприятием, позволившим миру обратить внимание на вопросы устойчивой энергетики и побудить диалог и сотрудничество между различными странами в этой области. Казахстан также использовал это событие, чтобы продемонстрировать свой потенциал в развитии инноваций и устойчивых технологий.
    `;
  }
  return (
    <div className=" px-4 flex flex-col mx-auto max-w-screen-xl   font-montserrat ">
      {/* карточки */}
      <div>
        <div className="flex flex-col w-full ">
          <span className="mt-4 text-[#333]   text-lg font-semibold">
            {cardText}
          </span>
          <div className="flex-col p-4 ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="65"
              height="45"
              viewBox="0 0 65 45"
              fill="none"
              className="mb-2"
            >
              <path
                d="M51.56 44.043C57.9106 43.2799 62.7408 38.5683 63.463 32.4505C63.7812 29.782 63.0383 26.5085 61.6305 24.3192C59.5063 21.0237 56.3844 19.1622 52.3266 18.7697C49.3295 18.4694 48.478 17.8759 48.933 16.3958C49.2896 15.2419 51.6304 12.5133 53.516 11.0668C55.4632 9.56199 58.4831 7.70666 60.6897 6.65602C63.4634 5.33689 64.4487 3.87954 63.8427 1.9925C63.6077 1.23535 62.8424 0.478673 62.0281 0.176457C61.2016 -0.125748 59.4011 -0.0192688 57.9955 0.413105C56.479 0.880533 54.4573 1.77953 52.6946 2.78316C45.9274 6.62264 40.9738 11.3344 37.5876 17.1516C36.2454 19.4599 35.2241 22.0941 34.6837 24.6114C34.303 26.4061 34.2815 29.9482 34.6405 31.4859C36.3987 39.1512 42.4956 44.2725 49.7102 44.1612C50.2528 44.149 51.079 44.09 51.56 44.043Z"
                fill="#2C4FA4"
              />
              <path
                d="M17.0906 44.0855C20.2228 43.6749 22.8979 42.4374 25.0419 40.4081C29.6751 36.0346 30.3716 28.7516 26.6547 23.5116C25.8891 22.4287 24.0624 20.7991 22.9396 20.2059C21.7798 19.5777 20.2871 19.1013 19.0043 18.9277C18.4863 18.8583 17.6475 18.7425 17.1419 18.6847C15.7728 18.4995 15.3286 18.3484 14.8843 17.9294C14.5757 17.6383 14.4769 17.4403 14.4766 17.1258C14.476 16.45 14.9191 15.5407 15.7938 14.433C17.9126 11.7512 21.1416 9.34807 26.1464 6.74525C28.6488 5.44966 29.5235 4.48178 29.5222 3.01367C29.5213 2.04657 29.2371 1.41763 28.5213 0.812384C27.8178 0.218775 27.2503 0.0328559 26.128 0.0338614C23.5752 0.0361487 19.7042 1.69416 15.0945 4.7627C6.79949 10.2698 1.1337 18.3728 0.104722 26.1571C-0.0422791 27.2641 0.0341802 30.0139 0.244817 31.1089C0.60418 33.0195 1.53131 35.4539 2.49468 37.026C4.10023 39.5879 6.84023 41.9741 9.45575 43.067C11.1706 43.7762 12.6755 44.0895 14.6241 44.146C15.5368 44.1685 16.6467 44.1442 17.0906 44.0855Z"
                fill="#2C4FA4"
              />
            </svg>

            <span className="text-[#2C4FA4] font-bold text-xl  ">
              {cardTextMain}
            </span>
          </div>
          <span className="mt-4 text-[#333]   text-lg font-semibold">
            {cardText2}
          </span>
        </div>
      </div>
    </div>
  );
}

export default TheAchievementCard;
